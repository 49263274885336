import Styles from './InlineCrumblepath.scss';

import Template from './InlineCrumblepath.hbs';
export default class InlineCrumblepath extends BaseView {

    initialize({
        fragments,
        pageType,
        isLink = true,
        autoScale = false,
    }) {

        // All items are expected to be objects, with the following properties:
        //   - id (id of the model item)
        //   - level (f.e.: groups, chapters, etc.)
        //   - label (name of the model item)
        // Optional attributes:
        //   - index (index to prefix label)
        //   - isHidden (to hide this model from crumblepath)
        const items = (typeof this.model?.getAllCrumblepathModels === 'function')
            ? this.model.getAllCrumblepathModels().map(model => {
                return {
                    ...model.attributes,
                    // Rename path_id to id
                    id: model.get('path_id')
                }
            })
            : fragments;

        if (!items || items.length === 0) {
            console.error('No (valid) items are passed to the inlineCrumblepath');
            return;
        }

        this.setElement(Template({
            Styles,
            items,
            isLink,
            pageType,
            autoScale
        }))

        if (autoScale) {
            if ('ResizeObserver' in window) {
                this.resizeObserver = new ResizeObserver(() => {
                    window.requestAnimationFrame(() => {
                        this.resizeCrumblepath()
                    })
                })
                this.resizeObserver.observe(document.body)
            }
        }
    }

    resizeCrumblepath() {

        // Start by showing the full crumblepath
        for (const label of this.el.querySelectorAll('.js-label-item[data-hide=true]')) {
            label.removeAttribute('data-hide')
        }

        // If the crumblepath does not fit, look for the first visible label with an index and hide it
        // If that is not enough, continue to the next one
        let label = this.el.querySelector('.js-label-item:not([data-hide=true])')
        while (label && this.el.scrollWidth > this.el.clientWidth) {
            label.setAttribute('data-hide', 'true')
            label = this.el.querySelector('.js-label-item:not([data-hide=true])')
        }
    }

    onDestroy() {
        this.resizeObserver?.disconnect()
    }
}
