export function playDummyAudio() {
    new Audio('data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA').play();
}

export async function fetchAudio(url, asBlob = false) {
    if (!url) {
        throw new Error('TextToSpeech URL cannot be empty');
    }

    try {
        const response = await fetch(url);

        if (!response.ok) {
            window.sentry.withScope(scope => {
                scope.setExtra('response', response);
                window.sentry.captureException('Synthesize returned non-ok response');
            });
            throw new Error('Something went wrong while trying to download audio');
        }

        if (response.headers.get('content-type')?.includes('application/json')) {
            const json = await response.json();

            if (json.status === 'error') {
                window.sentry.withScope(scope => {
                    scope.setExtra('json', json);
                    window.sentry.captureException(json.message);
                });
            }

            if (json.error_code === 35202) {
                json.displayMessage = window.i18n.gettext('Audio is not ready yet. Please wait.')
            }

            throw json;
        }

        const arrayBuffer = await response.arrayBuffer();
        return asBlob ? new Blob([arrayBuffer], { type: 'audio/mp3' }) : arrayBuffer;
    } catch (error) {
        if (error.displayMessage) {
            throw error;
        }

        // probably network error
        error.status = 'error';
        error.displayMessage = window.i18n.gettext('Something went wrong while trying to download audio');
        throw error;
    }
}
